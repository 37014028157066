import React from 'react';
import {Prose} from '@nikolovlazar/chakra-ui-prose';
import {
  MDXEditor,
  MDXEditorMethods,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  tablePlugin,
  toolbarPlugin,
  imagePlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import MDXEditorToolbarContents from './MDXEditorToolbarContents.tsx';
import {v4 as uuidv4} from 'uuid';
import supabaseClient from '../../api/supabaseClient.ts';
import {sharedMarkdownStylesString} from './sharedStyles.ts';
interface MDXEditorComponentProps {
  description: string;
  setDescription: (markdown: string) => void;
  markdownRef: React.RefObject<MDXEditorMethods>;
  placeholder: string;
}

const MDXEditorComponent: React.FC<MDXEditorComponentProps> = ({
  description,
  setDescription,
  markdownRef,
  placeholder,
}) => {
  async function imageUploadHandler(image: File) {
    const uniqueId = uuidv4();
    await supabaseClient.storage.from('markdownImages').upload(`${uniqueId}`, image, {
      cacheControl: '3600',
      upsert: false,
    });
    const {data: pathData} = supabaseClient.storage
      .from('markdownImages')
      .getPublicUrl(`${uniqueId}`);
    return pathData.publicUrl;
  }
  return (
    <Prose>
      <style>{sharedMarkdownStylesString}</style>
      <MDXEditor
        markdown={description}
        ref={markdownRef}
        placeholder={placeholder}
        contentEditableClassName="prose"
        plugins={[
          linkPlugin(),
          linkDialogPlugin(),
          listsPlugin(),
          tablePlugin(),
          headingsPlugin(),
          toolbarPlugin({
            toolbarContents: MDXEditorToolbarContents,
          }),
          imagePlugin({
            imageUploadHandler,
            disableImageSettingsButton: true,
          }),
        ]}
        onChange={(markdown: string) => {
          setDescription(markdown);
        }}
      />
    </Prose>
  );
};

export default MDXEditorComponent;

import PaginatedResponse from './PaginatedResponse';

export enum NotificationType {
  STEP_COMMENT = 'step_comment',
  COMMUNITY_COMMENT = 'community_comment',
  COMMUNITY_POST = 'community_post',
}

export interface CommentUser {
  firstName: string;
  lastName: string;
}

export interface CommentStep {
  amityCommentsPostId: string;
  amityQuestionsPostId: string;
  uuid: string;
  textStepTitle: string;
  video: CommentVideo;
  textStepThumbnailUrl: string;
  textStepThumbnails: Record<string, string> | null;
  type: string;
}

export interface CommentVideo {
  id: number;
  title: string;
  description: string;
  thumbnailUrl: any;
  published: boolean;
  stage: any;
  updatedAt: string;
  createdAt: string;
}

export interface CommentProgram {
  title: string;
}

export enum CommentPreviewType {
  QUESTION = 'question',
  COMMENT = 'comment',
}

export interface CommentPreviewDay {
  uuid: string;
  id: number;
  title: string;
  updatedAt: string;
  createdAt: string;
  orderIndex: number;
}

export interface Notification {
  id: number;
  amityCommentId?: string;
  amityParentCommentId?: string;
  amityPostId?: string;
  text: string;
  date: string;
  updatedAt: string;
  read: boolean;
  user: CommentUser;

  step: CommentStep | null;
  program: CommentProgram | null;
  day: CommentPreviewDay | null;
  commentPreviewType: CommentPreviewType | null;
  notificationType: NotificationType;
}

// Type for the specific response structure
export type NotificationsResponse = PaginatedResponse<Notification>;

import {
  Box,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  Text,
  Grid,
  GridItem,
  HStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import {CommunityRepository, PostRepository} from '@amityco/ts-sdk';

import {useUpdateEffect} from 'react-use';
import {useParams, Link as ReactRouterLink} from 'react-router-dom';
import PostDetails from './PostDetails/PostDetails.tsx';
import {AmitySdkPost} from '../../types/amity/AmitySdkPost.ts';
import {useQuery} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {SystemPost} from '../../types/Communities/SystemPost.ts';
import queryClient from '../../api/queryClient.ts';
import {SystemPostComponent} from './SystemPostComponent.tsx';
import LoadingData from '../../components/Common/LoadingData.tsx';

export default function CommunitiesPage() {
  const [community, setCommunity] = useState<Amity.Community | null>(null);
  const [loadingCommunity, setLoadingCommunity] = useState(false);
  const [CommunityError, setCommunityError] = useState<string | null>(null);

  const [communityPosts, setCommunityPosts] = useState<AmitySdkPost[]>([]);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [postsError, setPostsError] = useState<string | null>(null);
  const [hasMorePosts, setHasMorePosts] = React.useState<boolean | undefined>(false);

  const [pinnedPosts, setPinnedPosts] = useState<AmitySdkPost[]>([]);
  const [loadingPinnedPosts, setLoadingPinnedPosts] = useState(false);
  const [pinnedPostsError, setPinnedPostsError] = useState<string | null>(null);

  const loadMoreFnRef = useRef<(() => void) | undefined>(null);

  const {id} = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const draftPosts = useQuery({
    queryKey: ['draftPosts', id],
    queryFn: async () => {
      const response = await AxiosInstance.get<SystemPost[]>(
        `/admin/communities/${id}/posts/drafts`,
      );
      return response.data;
    },
    enabled: !!community,
  });

  const scheduledPosts = useQuery({
    queryKey: ['scheduledPosts', id],
    queryFn: async () => {
      const response = await AxiosInstance.get<SystemPost[]>(
        `/admin/communities/${id}/posts/scheduled`,
      );
      return response.data;
    },
    enabled: !!community,
  });

  useEffect(() => {
    /*
     * Possible params for getCommunities:
     * membership?: 'all' | 'member' | 'notMember'
     * categoryId?: 'categoryId'
     * includeDeleted?: true | false
     * tags?: ['tag1', 'tag2']
     * sortBy?: 'firstCreated' | 'lastCreated'
     */
    const unsubscriber = CommunityRepository.getCommunity(
      id!,
      ({data: community, loading, error}) => {
        if (error) {
          setCommunityError('There was an error getting the Community.');
          setLoadingCommunity(false);
          // Handle any errors that occur during retrieving data
        }
        if (loading) {
          setLoadingCommunity(true);
          // Handle the loading state, e.g., show a loading spinner
        }
        if (community) {
          // Process the data
          setCommunity(community);
          setLoadingCommunity(false);
          setCommunityError(null);
        }
      },
    );
    unsubscriber();
    return () => {
      setCurrentTab(0);
    };
  }, [id]);

  useUpdateEffect(() => {
    const pinnedPostOptions: Amity.PostLiveCollection = {
      targetId: id!,
      targetType: 'community',
      limit: 5,
      tags: ['pinned'],
    };

    draftPosts.refetch();
    scheduledPosts.refetch();

    const pinnedPostsUnsubsribe = PostRepository.getPosts(
      pinnedPostOptions,
      ({data, loading, error}) => {
        if (error) {
          // Handle any errors that occur during retrieving data
          setPinnedPostsError('There was an error getting the Pinned Posts.');
        }
        if (loading) {
          // Handle the loading state, e.g., show a loading spinner
          setLoadingPinnedPosts(true);
        }
        if (data) {
          // Process the data
          setPinnedPosts(data);
          setLoadingPinnedPosts(false);
          setPinnedPostsError(null);
        }
      },
    );

    const filterOptions: Amity.PostLiveCollection = {
      targetId: id!,
      targetType: 'community',
      limit: 20,
    };
    if (currentTab === 1) {
      filterOptions.tags = ['admin'];
    }

    const unsubscribe = PostRepository.getPosts(
      filterOptions,
      ({data, onNextPage, hasNextPage, loading, error}) => {
        const amityPosts = data as AmitySdkPost[];
        if (error) {
          // Handle any errors that occur during retrieving data
          setPostsError('There was an error getting the Posts.');
        }
        if (loading) {
          // Handle the loading state, e.g., show a loading spinner
          setLoadingPosts(true);
        }
        if (amityPosts) {
          const postWithoutPin = amityPosts.filter(p => !p.tags.includes('pinned'));

          // Process the data
          setCommunityPosts(postWithoutPin);
          setLoadingPosts(false);
          setPostsError(null);
        }
        setHasMorePosts(hasNextPage);
        loadMoreFnRef.current = onNextPage;
      },
    );

    return () => {
      unsubscribe();
      pinnedPostsUnsubsribe();
    };
  }, [currentTab, community]);

  function handleTabIndexChange(index: number) {
    setCurrentTab(index);
  }

  function handlePinPost(post: AmitySdkPost) {
    setPinnedPosts(prev =>
      [...prev, post].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    );
    setCommunityPosts(prev => prev.filter(p => p.postId !== post.postId));
  }

  function handleUnpinPost(post: AmitySdkPost) {
    setPinnedPosts(prev => prev.filter(p => p.postId !== post.postId));
    setCommunityPosts(prev =>
      [...prev, post].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    );
  }

  function handleDeletePost(post: AmitySdkPost) {
    setCommunityPosts(prev => prev.filter(p => p.postId !== post.postId));
    setPinnedPosts(prev => prev.filter(p => p.postId !== post.postId));
  }

  function handleLikePost(post: AmitySdkPost) {
    function addLikeReaction(allPosts: AmitySdkPost[]) {
      return allPosts.map(p =>
        p.postId === post.postId
          ? {
              ...p,
              reactions: {
                ...p.reactions,
                like: (p.reactions.like ?? 0) + 1,
              },
              myReactions: [...(p.myReactions ?? []), 'like'],
            }
          : p,
      );
    }

    setCommunityPosts(prev => addLikeReaction(prev));
    setPinnedPosts(prev => addLikeReaction(prev));
  }

  function handleUnlikePost(post: AmitySdkPost) {
    function removeLikeReaction(allPosts: AmitySdkPost[]) {
      return allPosts.map(p =>
        p.postId === post.postId
          ? {
              ...p,
              reactions: {
                ...p.reactions,
                like: p.reactions.like - 1,
              },
              myReactions: p.myReactions?.filter(r => r !== 'like'),
            }
          : p,
      );
    }
    setCommunityPosts(prev => removeLikeReaction(prev));
    setPinnedPosts(prev => removeLikeReaction(prev));
  }

  function setPostCommentsCount(postId: string, count: number) {
    setCommunityPosts(prev =>
      prev.map(p => (p.postId === postId ? {...p, commentsCount: count} : p)),
    );
    setPinnedPosts(prev =>
      prev.map(p => (p.postId === postId ? {...p, commentsCount: count} : p)),
    );
  }

  function handleUpdatePost(post: Partial<AmitySdkPost>) {
    setCommunityPosts(prev =>
      prev.map(p => (p.postId === post.postId ? {...p, ...post} : p)),
    );

    setPinnedPosts(prev => prev.map(p => (p.postId === post.postId ? {...p, ...post} : p)));
  }

  function handleDeleteDraftPost(post: SystemPost) {
    queryClient.setQueryData(['draftPosts', id], (data: SystemPost[]) =>
      data.filter(p => p.id !== post.id),
    );
  }

  function handleDeleteScheduledPost(post: SystemPost) {
    queryClient.setQueryData(['scheduledPosts', id], (data: SystemPost[]) =>
      data.filter(p => p.id !== post.id),
    );
  }

  function getMainContent() {
    const loading =
      (loadingCommunity && !community) ||
      (loadingPosts && communityPosts.length === 0) ||
      (loadingPinnedPosts && pinnedPosts.length === 0);

    if (loading) {
      return <LoadingData text="Loading Community" />;
    }

    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (CommunityError || postsError || pinnedPostsError) {
      return <Text>{CommunityError}</Text>;
    }

    return (
      <>
        <HStack
          justifyContent="space-between"
          borderBottom="1px solid #CFD6E1"
          paddingBottom={2}>
          <Text fontSize="36px" lineHeight="42px" fontWeight="700" color="#000000">
            {community?.displayName}
          </Text>

          <ChakraLink as={ReactRouterLink} to={`/communities/${id}/create-post`}>
            <Button colorScheme="black" size="md">
              Create
            </Button>
          </ChakraLink>
        </HStack>

        <Tabs
          w="fit-content"
          mt={4}
          position="relative"
          colorScheme="black"
          index={currentTab}
          onChange={handleTabIndexChange}
          mb={6}>
          <TabList>
            <Tab>All</Tab>
            <Tab>Admin Posts</Tab>
          </TabList>
        </Tabs>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mt={8}>
          <GridItem colSpan={7}>
            {pinnedPosts.map(post => (
              <PostDetails
                key={post.postId}
                post={post}
                onPinPost={() => handlePinPost(post)}
                onUnpinPost={() => handleUnpinPost(post)}
                onDeletePost={() => handleDeletePost(post)}
                onLikePost={() => handleLikePost(post)}
                onUnlikePost={() => handleUnlikePost(post)}
                setPostCommentsCount={setPostCommentsCount}
                onUpdatePost={handleUpdatePost}
                isPinned
              />
            ))}
            {communityPosts.map(post => (
              <PostDetails
                key={post.postId}
                post={post}
                canPinPost={pinnedPosts.length < 3}
                onPinPost={() => handlePinPost(post)}
                onUnpinPost={() => handleUnpinPost(post)}
                onDeletePost={() => handleDeletePost(post)}
                onLikePost={() => handleLikePost(post)}
                onUnlikePost={() => handleUnlikePost(post)}
                setPostCommentsCount={setPostCommentsCount}
                onUpdatePost={handleUpdatePost}
              />
            ))}
            {(hasMorePosts || (loadingPosts && communityPosts.length > 0)) && (
              <Flex justifyContent="center" alignItems="center">
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    loadMoreFnRef.current?.();
                  }}
                  isLoading={loadingPosts}>
                  Load more
                </Button>
              </Flex>
            )}
          </GridItem>

          <GridItem colSpan={5}>
            <Box mb="4">
              <SystemPostComponent
                posts={draftPosts.data ?? []}
                header="Drafts"
                onDeletePost={handleDeleteDraftPost}
                loading={draftPosts.isLoading}
              />
            </Box>
            <Box mb="4">
              <SystemPostComponent
                posts={scheduledPosts.data ?? []}
                header="Scheduled Posts"
                onDeletePost={handleDeleteScheduledPost}
                loading={scheduledPosts.isLoading}
              />
            </Box>
          </GridItem>
        </Grid>
      </>
    );
  }

  return (
    <Box height="auto" key={id}>
      <Text fontSize="16px" lineHeight="24px" fontWeight="400" color="#8E959E" mb={1}>
        Community
      </Text>
      {getMainContent()}
    </Box>
  );
}

import {Client, API_REGIONS, UserRepository} from '@amityco/ts-sdk';
import {EnvConfig} from '../base/EnvConfig';
import {AxiosInstance} from './_AxiosInstance';
import {User} from '../types/User';
import {amityApi} from './amityApi/amityApi';

// Only required to do once in the lifetime of the application
export const client = Client.createClient(EnvConfig.AMITY_API_KEY, API_REGIONS.US); // SG is the default
/*
 *  Check the session handler section in session state core concept for full details
 */

export const sessionHandler: Amity.SessionHandler = {
  sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
    // for details on other renewal methods check session handler
    renewal.renew();
  },
};

export async function initializeAmity(userData: User | null = null) {
  let user = userData;
  if (!user) {
    const response = await AxiosInstance.get<User>('/users/me');
    user = response.data;
  }

  const displayName =
    user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

  await Client.login(
    {
      authToken: EnvConfig.AMITY_API_AUTH_TOKEN,
      userId: user.email,
      displayName: displayName,
    },
    sessionHandler,
  );

  if (user.profilePictureUrl) {
    await UserRepository.updateUser(user.email, {
      avatarCustomUrl: user.profilePictureUrl,
    });
  }

  amityApi.defaults.headers.common.Authorization = `Bearer ${client.token?.accessToken}`;
}
